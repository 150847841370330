import theme from '@/theme'
import { InputLabel, makeStyles, MenuItem, Select, SelectProps } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React, { ReactNode, useState } from 'react'

export type SelectSizeProps = 'sm' | 'lg'
export interface MenuItemProps {
    id: number
    value: string
}
interface SelectFieldProps {
    menuItems: Array<MenuItemProps>
    size?: SelectSizeProps
    inputLabel?: string | ReactNode
}

function SelectField({ inputLabel, menuItems, size = 'lg', className, ...props }: SelectProps & SelectFieldProps) {
    const [open, setOpen] = useState(false)

    const useStyles = makeStyles({
        root: {
            width: '100%',
            borderRadius: 5,
            backgroundColor: theme.colors.white,
            '&:hover': {
                backgroundColor: theme.colors.white,
                cursor: 'pointer',
            },
        },
        inputOutlined: {
            height: size === 'lg' ? 60 : 38,
            padding: '0 14px',
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            margin: '2px 0 0',
        },
        label: {
            fontSize: 14,
            fontWeight: 600,
            color: '#000',
            marginBottom: 5,
        },
        disabled: {
            backgroundColor: '#BABABA',
            color: theme.colors.white,
        },
    })

    const { root, icon, inputOutlined, disabled, ...classes } = useStyles()

    return (
        <div>
            {inputLabel && <InputLabel classes={{ root: classes.label }}>{inputLabel}</InputLabel>}
            <Select
                {...props}
                variant="outlined"
                className={`${root} ${className}`}
                classes={{ icon, outlined: inputOutlined, disabled }}
                open={open}
                onClick={() => setOpen(!open)}
                displayEmpty
                IconComponent={ExpandMore}
                renderValue={(value) => {
                    const newValue = menuItems.find((item) => item.id === value)?.value
                    return <span>{(newValue as string) || props.placeholder}</span>
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                }}
            >
                {menuItems.length > 0 &&
                    menuItems.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.value}
                        </MenuItem>
                    ))}
            </Select>
        </div>
    )
}

export default SelectField
